import React, { useState, useEffect } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import AddPhotoMultiple from './AddPhotoMultiple';
import {serverFetchDataResult} from '../services/serverFetch'
import PhotoList from './PhotoList';

// Camera
export default props => {

        const {subdir} = props
        const [list, setList] = useState([])

        useEffect(()=>{
                const url = '/listData?subdir=' + subdir
                serverFetchDataResult(url, '', '', list=> {setList(list)});
        },[subdir])

        return(
                <div style = {{marginLeft:'auto', marginRight:'auto', marginTop:50}} className='columns'>
                        <div className='is-2 column'>
                            <AddPhotoMultiple subdir={subdir} list={list} setList={setList} />
                        </div>
                        <div className='column'>
                            <PhotoList subdir={subdir} list={list} setList={setList} />
                        </div>
                </div>
        )
}
      