import React, {useState, useEffect} from 'react';
import { useSharedState} from '../store';
import {serverFetchDataResult} from '../services/serverFetch';
import Camera from '../camera/Camera'
import Button, { buttonClasses } from '@mui/material/Button';
const SUBDIR = process.env.REACT_APP_IMAGE_SUBDIR

export default props => {   
    const [userSettings] = useSharedState()
    const [dirs, setDirs] = useState([])
    const [subdir, setSubdir] = useState(undefined)

    useEffect(() => {
      const url = '/listDirs?subdir=' + (props.subdir?props.subdir:SUBDIR)
      serverFetchDataResult(url, '', '', dirs=> {setDirs(dirs)});
    }, [])

    const handleClick = dir => setSubdir(props.subdir + '/' + dir)

    return(
    <div className='columns p-2 is-centered is-vcentered is-align-items-center is-flex-wrap-wrap' style={{}}>
      {userSettings.email?
        <>
          {dirs.map(dir=>
            <div className='column is-2'>
                <Button variant='outlined' style={{color:'black', borderColor:'black'}} onClick={()=>handleClick(dir)}>
                    {dir}
                </Button>
            </div>
          )}
          {subdir?
            <div style={{width:'100%'}}>
                <Camera subdir = {subdir} />
            </div> 
          :null}
        </>
      :null}  
    </div>
  )
}    
