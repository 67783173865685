import React, {useState, useEffect} from 'react';
import { useSharedState} from '../store';
import {serverFetchDataResult} from '../services/serverFetch';
import Pdf from '../camera/Pdf'
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const SUBDIR = process.env.REACT_APP_PDF_SUBDIR

export default props => {   
    const [userSettings,] = useSharedState()
    const subdir = props.subdir?props.subdir:SUBDIR //images/230928_Hos_Soren'  
    const [list, setList] = useState([])
    useEffect(() => {
      const url = '/listPdf?subdir=' + subdir
      serverFetchDataResult(url, '', '', list=> {setList(list)});
    }, [subdir])
    const dirname = apiBaseUrl + '/' + subdir.trim('/')
    return(
    <div className='columns p-6 is-centered is-vcentered is-align-items-center is-flex-wrap-wrap' >
        <>
          {list?list.map(fi=>
              <div className='column is-4'>
                <Pdf dirname={dirname} fname={fi}/>
              </div>
            )
          :<h1>No list + {dirname}</h1>}
        </>
    </div>
  )
}    
